// This module allows for a submit button to be disabled until a certain
// element has a value.
//
// <form data-submit-disabled="#my-select">
//  <select id="my-select">
//  </select>
//
//  <button type="submit">Submit</button>
// </form>
module.exports = (function($) {
  $(function() {
    $('[data-disable-submit]').each(function(index, element) {
      var $element = $(element);
      var $dependsOn = $element.find($element.data('disable-submit'));
      var $submit = $element.find('[type="submit"]');

      if ($dependsOn.val() === '') {
        $submit.prop('disabled', true);
      }

      $dependsOn.on('change', function() {
        $submit.prop('disabled', $dependsOn.val() === '');
      });
    });
  });
})(jQuery);
