/*
	Dependencies: intro.js, introjs css, jQuery

	NOTE: The button is default to be hidden on load unless there are `tourData` passed into `tour.init({tourData: data})`

	Usage: tour.init({tourData: [Array of Objects]});

	With options:

	var opts = {
		startTourBtn: [String], // Class, name, data attrs, whatever,
		alertBox: [String], //This is the first-time welcome alert message, class, id, whatevs
		tourData: [Array of objects]
	}

	tourData format:
	[
		{
			element: [jQuery Object][0] or document.querySelector('elementName'),
			intro: '[string|html]'
		}
	]

	tour.init(opts);

	Tour setup:

	<aside id="tour" class="hidden" data-show-tour="{% if request.user.show_help %}1{% else %}0{% endif %}">
	    <a href="#" data-tour="start" class="tour-link">
	        <i class="fa fa-map-signs"></i>
	        <small class="tour-text">Tour</small>
	    </a>
	</aside>

	Trigger Elements:
		Click on an element as the tour goes to show hidden feature of the app such as Dropdown menu

		const hasTrigger: [String] - Data attribute in camelCase should attach to the root of the introjs element. Default is `data-has-trigger="true"`.

		const triggerElement: [String] - Data attribute in camelCase. So we can target a specific element to click on. The default is `data-trigger-element="click"`.

	Trigger Element Example:

	<div class="dropdown" data-has-trigger="true">
        <a class="dropdown-toggle" data-trigger-element="click" data-toggle="dropdown">
            This is a trigger element
        </a>

        <div class="dropdown-menu">
            <a href="#" class="dropdown-item">Dropdown Item 1</a>
            <a href="#" class="dropdown-item">Dropdown Item 2</a>
            <a href="#" class="dropdown-item">Dropdown Item 3</a>
        </div>
    </div>
 */

module.exports = (function() {

	var _settings = null;

	const _const = {
		showTour: 'showTour', //data-attr attached to the tour button to show the alert box
		hasTrigger: 'hasTrigger',
		triggerElement: '[data-trigger-element]',
	};

	var _init = function(opts) {
		_settings = {
			alertBox: '.alert-welcome',
			startTourBtn: '[data-tour="start"]',
			tourRoot: '#tour',
			trainingRoot: '#training',
			tourData: null
		};

		$.extend(_settings, opts);

		if(_settings.tourData) {
			_setup();
		}
	};

	var _setup = function() {

		$(_settings.tourRoot).removeClass('hidden');

		// remove hidden class from training
		$(_settings.trainingRoot).removeClass('hidden');

		intro.setOptions({
			steps: _settings.tourData,
			skipLabel: 'End Tour',
			doneLabel: 'End of Tour',
			scrollToElement: true,
			showBullets: false,
			showProgress: true,
			showStepNumbers: false,
			exitOnOverlayClick: false
		}).oncomplete(_endTour);

		$(_settings.startTourBtn).click(function() {
			_backToTop(); //Starts from top to avoid weirdness with the sticky nav
			_startTour();
			return false;
		});
	};

	var _backToTop = function() {
		$(window).scrollTop(0);
	}

	//This alert box shows when `show_help=1` that introduces new user to the tour
	var _dismissAlert = function (alertBox) {
		alertBox = alertBox || $(_settings.alertBox);
		if(alertBox.length) alertBox.alert('close');
	};

	var _endTour = function() {
		//New user's default show_help=1, so when they login for the first time tour alert will show
		if($(_settings.tourRoot).data(_const.showTour) === 1) {
			//Set show_help to 0
			window.location.href="/sethelp/?next=/dashboard/&show_help=0";
		} else {
			intro.exit();
		}
	}

	var _startTour = function() {
		//_dismissAlert();
		intro.start();
		if($(_const.triggerElement).length) {
			_triggerElement();
		}
	};

	//Click on an element as the tour goes to show hidden feature of the app such as Dropdown menu
	var _triggerElement = function() {
		intro.onbeforechange(function(el) {
			_closePrevElement();
		}).onchange(function(el) {
			_clickElement(el);
		});
	};

	var _closePrevElement = function() {
		if($(_const.triggerElement).length) {
			$('.is-triggered').click().removeClass('is-triggered');
		}
	};

	var _clickElement = function(el) {
		if($(el).data(_const.hasTrigger)) {
			$(el).find(_const.triggerElement).click().addClass('is-triggered');
		}
	}

	var _getSettings = function() {
		return _settings;
	}

	return {
		init: _init,
		dismissWelcome: _dismissAlert,
		getSettings: _getSettings,
		clickElement: _clickElement,
		closePrevElement: _closePrevElement
	}

})();