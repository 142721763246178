'use strict';

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

var datepicker = require('bootstrap-datepicker');
window.datePicker = datepicker;

var popper = require('popper.js');
window.Popper = popper;

var tether = require('tether');
window.Tether = tether;

var bootstrap = require('bootstrap');

var Sortable = require('sortablejs');
window.Sortable = Sortable;

var tour = require('./components/tour-guide.js');
window.tour = tour;

var swipe = require('jquery-touchswipe');
window.swipe = swipe;

var Bloodhound = require('bloodhound-js');
var typeahead = require('corejs-typeahead');
var flatpickr = require('flatpickr');

$(function() {


	var introJs = require('intro.js');
	window.intro = introJs.introJs();

	$('[data-toggle="tooltip"]').tooltip({
		animation: false
	});

  $('[data-toggle="modal"]').click(function() {
    $('#'+$(this).data('target')).modal('show', $(this));
  });

	//Detects IE10 or older
	var nonSupported = '<div class="messages"><div class="alert alert-warning" role="alert"><strong>Warning!</strong> This application does not support <strong>Internet Explorer 10 and below</strong>. Please contact your IT Administrator to upgrade to a modern browser.</div></div>';
	if (navigator.userAgent.match(/msie/i)){
        $('body').append(nonSupported);
    }

  var backlink = $('[data-js="backlink"]');
	if(backlink.length) {
		backlink.click(function() {
				window.history.back();
			return false;
		});
	}



	var smoothScroll = require('./components/smoothscroll.js');
	window.smoothScroll = smoothScroll;
	var $hash = window.location.hash;
	smoothScroll.init({
		offset: 90
	});

	if($hash) {
		smoothScroll.to($hash);
	}

	var sticky = require('./components/sticky');
	window.sticky = sticky;

	sticky.init();



  //search

     // instantiate the bloodhound suggestion engine
    var engine = new Bloodhound({
        datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.tokens.join(' ')); },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: '/search/?q=%QUERY',
          wildcard: '%QUERY',
          filter: function(response) {
            return response.suggestions
          }
        }
    });

  $('#id_search_q').typeahead(
      {
        hint: true,
        highlight: true,
        minlength: 1
      },
      {
        name: 'results',
        displayKey:'value',
        source: engine.ttAdapter(),
        templates: {
          header: '<p>Search results</p>',
          suggestion: function(result){
            var p = document.createElement('p');
            if(result.data.category == 'organization') {
              p.appendChild(document.createTextNode('Organization: '));
              p.appendChild(document.createTextNode(result.value));
            }
            else {
              p.appendChild(document.createTextNode(result.data.category));
              p.appendChild(document.createTextNode(': '));
              p.appendChild(document.createTextNode(result.value));
            }

            return p.outerHTML;
          }
        }
      }
  );

  $('.twitter-typeahead').bind('typeahead:select', function(event, suggestion){
      window.location.href = suggestion.data.link;
  });

  $('.twitter-typeahead').on('keyup', function(e) {
    if(e.which == 13) {
        $(".tt-suggestion:first").trigger('click');
    }
  });

  $(".add-another-button").click(function(event){
    var input = $('#id_add_another');
    input.val(1);
    var form = $(event.target).closest('form');
    form.submit();
  });

  require('./components/submit-disabled.js');
  require('./components/mentor_selection.js')
});
