/*
	Sticky - stick any containers to the top when scroll pass a certain point.

	Dependencies: jQuery, _sticky.scss

	Params: object
		- container: 'string'
		- offset: 'number'

	Markups:

		<div data-js="sticky">This will be sticky after scroll passed it.</div>

	Usage:

		stick.init();

		var opts = {container: '.custom-container', offset: 100 }
		sticky.init(opts);
 */

module.exports = (function($) {

	var _settings;

	var _init = function(opts) {
		_settings = {
			container: '[data-js="sticky"]'
		};

		$.extend(_settings, opts);

		if($(_settings.container).length) {
			_setup();
		};
	};

	var _setup = function() {

		_settings.offset = $(_settings.container).offset().top;

		$(window).scroll(function() {
			$(document).scrollTop() >= _settings.offset ? _stick() : _unstick();
		});
	};

	var _getSettings = function() {
		return _settings;
	}

	var _stick = function() {
		$(_settings.container).addClass('is-stuck');
	};

	var _unstick = function() {
		$(_settings.container).removeClass('is-stuck');
	}

	return {
		init: _init,
		getSettings: _getSettings,
		stick: _stick,
		unstick: _unstick
	}

})(jQuery || {});