/**
 * Simple smooth scroll to content

 Usage:

 <a href="#linky" data-scroll-to="linky">Go to Linky</a>
 <div id="linky">...</div>

 smoothScroll.init({
	delay: 300, //Slight delay before the scrolling starts,
	offset: 0, //Offset from the container because sometimes it scrolls pass it or too little
	scrollSpeed: 800
 })

 */
module.exports = (function() {

	const _const = {
		scrollElements: '[data-scroll-to]'
	};

	var _configs = null,
		_position = 0;

  	var _init = function(opts) {

	  	_configs = {
	  		rootDiv: 'html,body',
	  		delay: 500,
	  		offset: 0,
	  		scrollSpeed: 800
	  	}

	  	$.extend(_configs, opts);

	  	if($(_const.scrollElements).length) {
	  		_setup();
	  	}

      return this;
	}

  var _setup = function() {
  	var $scrollElements = $(_const.scrollElements);
  	$scrollElements.each(function() {
  		$(this).click(function(event) {
  			event.preventDefault();

  			_to('#'+$(this).data('scrollTo'));

	  	});
  	});
  }

  var _to = function(hash) {
	var hashed = hash;

	_setPosition(hashed);

	$(_configs.rootDiv)
	  .delay(_configs.delay)
	  .animate({
	    scrollTop: _position
	  }, _configs.scrollSpeed
	);

	return this;
  };

  var _getSettings = function() {
  	return _configs;
  };

  var _setPosition = function (pos) {
  	if(typeof pos === 'string') {
  		//Avoid the page from "jumping"
  		window.history.pushState(null, null, pos);
  		_position = $(pos).offset().top - _configs.offset;
  	} else {
  		_position = pos;
  	}
  };

  var _getPosition = function() {
  	return _position;
  };

  return {
  	init: _init,
  	to: _to,
  	getSettings: _getSettings,
  	setPosition: _setPosition,
  	getPosition: _getPosition
  }

})();
