module.exports = (function($) {
  $(function() {
    $("[data-mentor-field-name]").change(function () {
        var url = $(this).attr("data-mentors-url");
        var mentorInputName = $(this).attr("data-mentor-field-name");
        var $schoolSelect = $(this).find('select');
        var school = $schoolSelect.val();
        var form = $schoolSelect[0].form;
        var $mentorInput = $(form.elements[mentorInputName]);
        var mentor = $mentorInput.val();

        $.ajax({
          url: url,
          data: {
            'school': school,
            'mentor': mentor
          },
          success: function (data) {
            if (school === $schoolSelect.val()) {
              $returnedInput = $($.parseHTML(data));
              $mentorInput.html($returnedInput.find('select').html());
            }
          }
        });
    })
  });
})(jQuery || {});
